import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import appConfig from 'configs/app.config'
import { openSocketNotification } from 'utils/notificate'
import { io } from 'socket.io-client'
import { setSocketTransactionData } from 'store/socket-transactions/transactionSlice'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const state = useSelector((state) => state)
    const layoutType = state?.theme?.layout?.type
    const isAdmin = state?.auth?.user?.authority[0] === 'Admin'
    const { authenticated } = useAuth()

    const dispatch = useDispatch()

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    const buildSocketConnection = (url) => {
        const socket = io(url, {
            transports: ['websocket'], // Specify WebSocket transport explicitly
        })
        socket.on('connect', () => {
            console.log('WebSocket connected')
        })

        socket.on('transactions', (data) => {
            dispatch(setSocketTransactionData(data))
            openSocketNotification({
                placement: 'bottom-start',
                data: data,
            })
        })

        socket.on('disconnect', () => {
            console.log('WebSocket disconnected')
        })

        // return () => {
        //     socket.disconnect() // Disconnect the WebSocket when component unmounts
        // }
    }


    useEffect(() => {
        if (authenticated && isAdmin) {
            let url = `${appConfig.socketUrl}`

            if (url == 'https://monex.minikubes.com/') {
                console.log('sandbox here')
                buildSocketConnection('https://monex.minikubes.com')
            } else if (url == 'https://ggnft.ibanera.com/') {
                buildSocketConnection('https://ggnft.ibanera.com')
               
            }
        }
    }, [authenticated, isAdmin])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
