const appConfig = {
    apiPrefix: process.env.REACT_APP_API_BASE_URL + 'api/',
    socketUrl: process.env.REACT_APP_SOCKET_URL,
    // apiPrefix: 'https://dfd9-2404-3100-188b-90b-41b0-c625-449d-1ae.ngrok-free.app/api/',
    // socketUrl: 'https://4dd8-2404-3100-188b-90b-ec8e-db3a-3805-14af.ngrok-free.app',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
