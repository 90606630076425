import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import Skeleton from 'components/ui/Skeleton'
import Card from 'components/ui/Card'
import { useSelector } from 'react-redux'
import { THEME_ENUM } from 'constants/theme.constant'

const SmallHalfPie = ({
    name,
    value,
    loading,
    heightOfPie,
    gridClass,
    chartName,
    isPercentage,
}) => {
    const mode = useSelector((state) => state.theme.mode)
    const { MODE_LIGHT } = THEME_ENUM

    const isEnabled = mode === MODE_LIGHT

    var title = name
    var total = value
    const chartRef = useRef(null)

    const resizeChart = () => {
        const chart = echarts.getInstanceByDom(chartRef.current)
        if (chart) {
            chart.resize()
        }
    }
    const formatNumber = (n) =>
        n > 100
            ? n > 999999
                ? `${(n / 1000000).toFixed(1)}m`
                : `${(n / 1000).toFixed(1)}k`
            : n.toString()

    useEffect(() => {
        if (!loading) {
            const chart = echarts.init(chartRef.current)

            var _panelImageURL = 'https://echarts.apache.org/examples/data/asset/img/custom-gauge-panel.png';
            var _animationDuration = 1000;
            var _animationDurationUpdate = 1000;
            var _animationEasingUpdate = 'quarticInOut';
            var _valOnRadianMax = 100;
            var _outerRadius = 25;
            var _innerRadius = 22;
            var _pointerInnerRadius = 15;
            var _insidePanelRadius = 17;
            var _currentDataIndex = 0;
            function renderItem(params, api) {
                var valOnRadian = api.value(1);
                var coords = api.coord([api.value(0), valOnRadian]);
                var polarEndRadian = coords[3];
                var imageStyle = {
                    image: _panelImageURL,
                    x: params.coordSys.cx - _outerRadius,
                    y: params.coordSys.cy - _outerRadius,
                    width: _outerRadius * 2,
                    height: _outerRadius * 2
                };
                return {
                    type: 'group',
                    children: [
                        {
                            type: 'image',
                            style: imageStyle,
                            clipPath: {
                                type: 'sector',
                                shape: {
                                    cx: params.coordSys.cx,
                                    cy: params.coordSys.cy,
                                    r: _outerRadius,
                                    r0: _innerRadius,
                                    startAngle: 0,
                                    endAngle: -polarEndRadian,
                                    transition: 'endAngle',
                                    enterFrom: { endAngle: 0 }
                                }
                            }
                        },
                        {
                            type: 'image',
                            style: imageStyle,
                            clipPath: {
                                type: 'polygon',
                                shape: {
                                    points: makePionterPoints(params, polarEndRadian)
                                },
                                extra: {
                                    polarEndRadian: polarEndRadian,
                                    transition: 'polarEndRadian',
                                    enterFrom: { polarEndRadian: 0 }
                                },
                                during: function (apiDuring) {
                                    apiDuring.setShape(
                                        'points',
                                        makePionterPoints(params, apiDuring.getExtra('polarEndRadian'))
                                    );
                                }
                            }
                        },
                        {
                            type: 'circle',
                            shape: {
                                cx: params.coordSys.cx,
                                cy: params.coordSys.cy,
                                r: _insidePanelRadius
                            },
                            style: {
                                fill: '#fff',
                                shadowBlur: 25,
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                shadowColor: 'rgba(76,107,167,0.4)'
                            }
                        },
                        {
                            type: 'text',
                            extra: {
                                valOnRadian: valOnRadian,
                                transition: 'valOnRadian',
                                enterFrom: { valOnRadian: 0 }
                            },
                            style: {
                                text: makeText(valOnRadian),
                                fontSize: 9,
                                fontWeight: 700,
                                x: params.coordSys.cx,
                                y: params.coordSys.cy,
                                fill: 'rgb(0,50,190)',
                                align: 'center',
                                verticalAlign: 'middle',
                                enterFrom: { opacity: 0 }
                            },
                            during: function (apiDuring) {
                                apiDuring.setStyle(
                                    'text',
                                    makeText(apiDuring.getExtra('valOnRadian'))
                                );
                            }
                        }
                    ]
                };
            }
            function convertToPolarPoint(renderItemParams, radius, radian) {
                return [
                    Math.cos(radian) * radius + renderItemParams.coordSys.cx,
                    -Math.sin(radian) * radius + renderItemParams.coordSys.cy
                ];
            }
            function makePionterPoints(renderItemParams, polarEndRadian) {
                return [
                    convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
                    convertToPolarPoint(
                        renderItemParams,
                        _outerRadius,
                        polarEndRadian + Math.PI * 0.03
                    ),
                    convertToPolarPoint(renderItemParams, _pointerInnerRadius, polarEndRadian)
                ];
            }
            function makeText(valOnRadian) {
                // Validate additive animation calc.
                if (valOnRadian < -10) {
                    alert('illegal during val: ' + valOnRadian);
                }
                return ((valOnRadian / _valOnRadianMax) * 100).toFixed(0) + '%';
            }
            var option = {
                animationEasing: _animationEasingUpdate,
                animationDuration: _animationDuration,
                animationDurationUpdate: _animationDurationUpdate,
                animationEasingUpdate: _animationEasingUpdate,
                dataset: {
                    source: [[1, total]]
                },
                // title: {
                //     top: '2%',
                //     left: 'center',
                //     text: `Total Sales`,
                //     textStyle: {
                //         color: '#000000',
                //         fontSize: 12,
                //     },
                // },
                tooltip: {},
                angleAxis: {
                    type: 'value',
                    startAngle: 0,
                    show: false,
                    min: 0,
                    max: _valOnRadianMax
                },
                radiusAxis: {
                    type: 'value',
                    show: false
                },
                polar: {},
                series: [
                    {
                        type: 'custom',
                        coordinateSystem: 'polar',
                        renderItem: renderItem
                    }
                ]
            };

            chart.setOption(option)

            // Listen for window resize events and update chart dimensions
            window.addEventListener('resize', resizeChart)

            return () => {
                // Remove the event listener when the component is unmounted
                window.removeEventListener('resize', resizeChart)
                chart.dispose()
            }
        }
    }, [name, total, chartName, isPercentage, isEnabled])

    return (
        <>
            {loading ? (
                <Skeleton
                    height={`${heightOfPie}px`}
                    width={'100%'}
                    className={gridClass}
                />
            ) : (
                <div
                    ref={chartRef}
                    style={{ width: '50px', height: `${heightOfPie}px` }}
                    className={gridClass}
                ></div>
            )}
        </>
    )
}

export default SmallHalfPie
