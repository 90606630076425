import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import Skeleton from 'components/ui/Skeleton'
import Card from 'components/ui/Card'
import { useSelector } from 'react-redux'
import { THEME_ENUM } from 'constants/theme.constant'

const SmallFullPie = ({
    name,
    value,
    loading,
    heightOfPie,
    gridClass,
    chartName,
    isPercentage,
    titleT
}) => {
    const mode = useSelector((state) => state.theme.mode)
    const { MODE_LIGHT } = THEME_ENUM

    const isEnabled = mode === MODE_LIGHT

    var title = name
    var total = value
    const chartRef = useRef(null)

    const resizeChart = () => {
        const chart = echarts.getInstanceByDom(chartRef.current)
        if (chart) {
            chart.resize()
        }
    }
    const formatNumber = (n) =>
        n > 100
            ? n > 999999
                ? `${(n / 1000000).toFixed(1)}m`
                : `${(n / 1000).toFixed(1)}k`
            : n.toString()

    useEffect(() => {
        if (!loading) {
            const chart = echarts.init(chartRef.current)

            var option = {
                title: {
                    top: 'center',
                    left: 'center',
                    text: titleT + `%`,
                    textStyle: {
                        color: '#0000FF',
                        fontSize: 8,
                    },
                },
                tooltip: {
                  trigger: 'item'
                },
                series: [
                  {
                    name: 'Merchants',
                    type: 'pie',
                    radius: ['40%', '50%'],
                    avoidLabelOverlap: false,
                    padAngle: 2,
                    itemStyle: {
                      borderRadius: 10
                    },
                    label: {
                      show: false,
                      position: 'center'
                    },
                    // emphasis: {
                    //   label: {
                    //     show: true,
                    //     fontSize: 8,
                    //   }
                    // },
                    labelLine: {
                      show: false
                    },
                    data: total
                  }
                ]
              };
            chart.setOption(option)

            // Listen for window resize events and update chart dimensions
            window.addEventListener('resize', resizeChart)

            return () => {
                // Remove the event listener when the component is unmounted
                window.removeEventListener('resize', resizeChart)
                chart.dispose()
            }
        }
    }, [name, total, chartName, isPercentage, isEnabled])

    return (
        <>
            {loading ? (
                <Skeleton
                    height={`${heightOfPie}px`}
                    width={'100%'}
                    className={gridClass}
                />
            ) : (
                <div
                    ref={chartRef}
                    style={{ width: '90px', height: `${heightOfPie}px` }}
                    className={gridClass}
                ></div>
            )}
        </>
    )
}

export default SmallFullPie
