import axios from 'axios'
import appConfig from 'configs/app.config'
import { REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { store } from 'store'
import { handleSignOut } from 'utils/helper'

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
    (config) => {
        const { auth } = store.getState()
        const accessToken = auth.session.token
        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error
        if (response.status === 401) {
            // store.dispatch(onSignOutSuccess())
            handleSignOut()
        }

        return Promise.reject(error)
    }
)

export default BaseService
