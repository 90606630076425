import ApiService from './ApiService.js'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: 'auth/sign-in',
        method: 'post',
        data,
    })
}

export async function apiOtp(data) {
    return ApiService.fetchData({
        url: 'auth/verify-otp/sign-in',
        method: 'post',
        data,
    })
}

export const resendOtp = async (data) => {
    try {
        const res = await ApiService.fetchData({
            url: 'auth/resend-otp',
            method: 'post',
            data,
        })
        return {
            status: true,
            res: res.data,
        }
    } catch (error) {
        return {
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: 'auth/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: 'auth/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: 'forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: 'reset-password',
        method: 'post',
        data,
    })
}
