import toast from 'components/ui/toast'
import Notification from 'components/ui/Notification'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { Avatar, Tag } from 'components/ui'
import { ORDER_STATUS } from 'constants/app.constant'

export const openNotification = (
    type = 'success',
    message,
    placement = 'top-end'
) => {
    toast.push(
        <Notification
            title={type.charAt(0).toUpperCase() + type.slice(1)}
            type={type}
            closable 
            
        >
            {message}
        </Notification>,
        { placement: placement }
        
    )
}

export const openSocketNotification = ({ type, placement, data }) => {
    let statusMap = ORDER_STATUS[data?.payment_status ?? 'UNKNOWN'] ?? {}
    toast.push(
        <Notification
            title={data.is_created ? 'Created' : 'Updated'}
            type={type || 'success'}
            duration={5000}
            closable
            customIcon={
                <Avatar
                    size={30}
                    className="bg-indigo-100 text-indigo-600 dark:bg-indigo-500/20 dark:text-indigo-100"
                    icon={<HiOutlinePresentationChartLine />}
                />
            }
            width={'100%'}
        >
            {/* {message || 'Created Done'} */}
            <CustomInfoField title={'Amount'} value={data.amount} />
            <CustomInfoField
                title={'Status'}
                value={
                    <Tag className={` ${statusMap.color} border-0 text-xs`}>
                        {statusMap.title ?? data?.payment_status}
                    </Tag>
                }
            />
            <CustomInfoField
                title={'Payment Method'}
                value={data.payment_method}
            />
            <CustomInfoField title={'Trx Id'} value={data.tx_id} />
        </Notification>,
        { placement: placement }
    )
}

const CustomInfoField = ({ title, value }) => {
    return (
        <div className="my-2">
            <span className="text-gray-700 dark:text-gray-200 font-semibold text-xs">
                {title}
            </span>
            <span className="ml-2 text-xs">{value}</span>
        </div>
    )
}
export default CustomInfoField
