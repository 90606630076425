import { createSlice } from '@reduxjs/toolkit'

export const transactionSocketSlice = createSlice({
    name: 'auth/session',
    initialState: null,
    reducers: {
        setSocketTransactionData: (state, action) => {
            return action.payload
        },
    },
})

export const { setSocketTransactionData } = transactionSocketSlice.actions

export default transactionSocketSlice.reducer
