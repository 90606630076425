import ApiService from 'services/ApiService'
import { store } from 'store'
import { onSignOutSuccess } from 'store/auth/sessionSlice'
import { initialState, setUser } from 'store/auth/userSlice'
import { v4 as uuidv4 } from 'uuid'
import MakeASun from 'views/ui-tools/MakeASun'
import SmallFullPie from 'views/ui-tools/SmallFullPie'
import SmallHalfPie from 'views/ui-tools/SmallHalfPie'

export const copyToClipBoard = async (copyMe) => {
    try {
        await navigator.clipboard.writeText(copyMe)
        return true
    } catch (err) {
        return false
    }
}

export const getDeviceUUID = () => {
    let deviceID = localStorage.getItem('device-id') || ''
    if (!deviceID) {
        deviceID = uuidv4()
        localStorage.setItem('device-id', deviceID)
    }
    return deviceID
}

export const handleSignOut = () => {
    store.dispatch(onSignOutSuccess())
    store.dispatch(setUser(initialState))
    localStorage.removeItem('user_info')
    localStorage.removeItem('login_auth_token')
    setTimeout(() => {
        window.location.reload()
    }, 1000)
}

export const deepClone = (data = []) => {
    return JSON.parse(JSON.stringify(data))
}

export const dateFormate = (date) => {
    if (!date) return '--'
    return new Date(date).toLocaleString()
}

export const getPreviousDayDate = (p_day = 30) => {
    const today = new Date()
    return new Date(new Date().setDate(today.getDate() - Number(p_day)))
}

export const showText = (row, col) => {
    if (col?.method) {
        return col.method(row[col?.accessorKey], row)
    }


    if (!row[col?.accessorKey] && row[col?.accessorKey] !== false) {
        return '--'
    }
    
    return row[col?.accessorKey]
    
    
}

export const scrollToErrorField = (errors = {}, isTop, callBack) => {
    if (Object.keys(errors || {}).length > 0 && isTop) {
        if (
            document.getElementsByName(Object.keys(errors)[0])[0].type ===
            'hidden'
        ) {
            const parentElement = document.getElementsByName(
                Object.keys(errors)[0]
            )[0].parentElement
            parentElement
                .getElementsByClassName('select__input')[0]
                .scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                })
        } else {
            document
                .getElementsByName(Object.keys(errors)[0])[0]
                .scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                })
        }
        callBack?.()
    }
}

export async function downloadReports(apiUrl, params, fileName) {
    try {
        const response = await ApiService.fetchData({
            url: apiUrl,
            method: 'get',
            params: params || {},
            responseType: 'blob',
        })
        const url = URL.createObjectURL(new Blob([response.data]))

        // Create an anchor element to trigger the download
        const a = document.createElement('a')
        a.href = url
        a.download = `${fileName}.xlsx` // Set your desired file name here
        a.style.display = 'none'
        document.body.appendChild(a)

        // Trigger a click event to initiate the download
        a.click()

        // Clean up
        URL.revokeObjectURL(url)
        document.body.removeChild(a)

        return 'success'
    } catch (error) {
        console.error('Error fetching data:', error)
        return 'fail'
    }
}

export const formatNumber = (n) => {
    if (n < 1e3) return n
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export const getTruncatedAddress = (str) => {
    if (str?.length > 0) {
        let firstFour = str.substring(0, 4)
        let lastFour = str.substring(str.length - 4)
        return firstFour + '***' + lastFour
    }
}
