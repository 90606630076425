import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import Skeleton from 'components/ui/Skeleton'
import Card from 'components/ui/Card'
import { useSelector } from 'react-redux'
import { THEME_ENUM } from 'constants/theme.constant'

const MakeASun = ({
    name,
    value,
    loading,
    heightOfPie,
    gridClass,
    chartName,
    isPercentage,
}) => {
    const mode = useSelector((state) => state.theme.mode)
    const { MODE_LIGHT } = THEME_ENUM

    const isEnabled = mode === MODE_LIGHT

    var title = name
    var total = value
    const chartRef = useRef(null)

    const resizeChart = () => {
        const chart = echarts.getInstanceByDom(chartRef.current)
        if (chart) {
            chart.resize()
        }
    }
    const formatNumber = (n) =>
        n > 100
            ? n > 999999
                ? `${(n / 1000000).toFixed(1)}m`
                : `${(n / 1000).toFixed(1)}k`
            : n.toString()

    useEffect(() => {
        if (!loading) {
            const chart = echarts.init(chartRef.current)

            const option = {
                title: [
                    {
                        text: formatNumber(total ?? 0),
                        x: 'center',
                        y: 'center',
                        textStyle: {
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: isEnabled ? '#000000' : '#FFFFFF',
                        },
                    },
                    {
                        text: title,
                        x: 'center',
                        y: '55%',
                        textStyle: {
                            fontWeight: 'bold',
                            fontSize: 10,
                            color: isEnabled ? '#000000' : '#FFFFFF',
                        },
                    },
                ],
                //toolbox: smallToolBox(),
                data: [
                    {
                        name: name,
                    },
                ],
                series: [
                    {
                        // 背景圆环
                        name: '',
                        type: 'pie',
                        radius: ['70%', '80%'],
                        silent: true,
                        startAngle: 225,
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        z: 5,
                        data: [
                            {
                                value: 75,
                                itemStyle: {
                                    color: '#6D69EA',
                                },
                            },
                            {
                                value: 25,
                                itemStyle: {
                                    color: 'transparent',
                                },
                            },
                        ],
                    },

                    {
                        // 中间圈
                        name: '',
                        z: 5,
                        type: 'pie',
                        cursor: 'default',
                        radius: ['60%', '60%'],
                        // center: ['50%', '60%'] ,
                        hoverAnimation: false,
                        legendHoverLink: false,
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [
                            {
                                value: 1,
                                itemStyle: {
                                    normal: {
                                        borderWidth: 1,
                                        borderColor: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#6D69EA',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#DDDDDD',
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            }

            chart.setOption(option)

            // Listen for window resize events and update chart dimensions
            window.addEventListener('resize', resizeChart)

            return () => {
                // Remove the event listener when the component is unmounted
                window.removeEventListener('resize', resizeChart)
                chart.dispose()
            }
        }
    }, [name, value, chartName, isPercentage, isEnabled])

    return (
        <>
            {loading ? (
                <Skeleton
                    height={`${heightOfPie}px`}
                    width={'100%'}
                    className={gridClass}
                />
            ) : (
                <Card
                    ref={chartRef}
                    style={{ width: '100%', height: `${heightOfPie}px` }}
                    className={gridClass}
                ></Card>
            )}
        </>
    )
}

export default MakeASun
