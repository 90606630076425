import { createSlice } from '@reduxjs/toolkit'

// export const initialState = {
//     id: '',
//     email: '',
//     phone: '',
//     authority: [],
// }

const initValue = JSON.parse(localStorage.getItem('user_info')) || {
    id: '',
    email: '',
    phone: '',
    authority: [],
}

export const initialState = { ...initValue }

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
